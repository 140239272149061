import IncogniaWebSdk from '@incognia/web-sdk';
import { getIncogniaAppId } from './environment';

enum IncogniaEvent {
  SMS = 'sms',
  ONBOARDING_FINISHED = 'onboarding_finished',
  EXISTING_REGISTRATION = 'existing_registration',
}

interface IncogniaPayload {
  account_id?: string;
  verification_attempt_id: string;
  phone_number_id: string;
}

export const initIncognia = (): void => {
  const appId = getIncogniaAppId();
  if (!appId) {
    return;
  }
  IncogniaWebSdk.init(appId);
};

const sendEvent = (event: IncogniaEvent, payload: IncogniaPayload): void => {
  const appId = getIncogniaAppId();
  if (!appId) {
    return;
  }
  IncogniaWebSdk.sendCustomEvent({
    tag: event,
    accountId: payload.account_id,
    externalId: payload.verification_attempt_id,
    properties: {
      phone_hash: payload.phone_number_id,
    },
  });
};

export const sendOtpTriggered = (payload: IncogniaPayload): void => {
  sendEvent(IncogniaEvent.SMS, payload);
};

export const sendOnboardingFinished = (payload: IncogniaPayload): void => {
  sendEvent(IncogniaEvent.ONBOARDING_FINISHED, payload);
};

export const sendExistingRegistration = (payload: IncogniaPayload): void => {
  sendEvent(IncogniaEvent.EXISTING_REGISTRATION, payload);
};
