export enum Environment {
  LOCAL = 'local',
  PRELIVE = 'prelive',
  LIVE = 'live',
  PRELIVEHOPP = 'prelivehopp',
  LIVEHOPP = 'livehopp',
}

export enum AppBrand {
  BOLT = 'bolt',
  HOPP = 'hopp',
}

export type StringPerEnvironment = { [key in Environment]: string };
export type StringPerBrand = { [key in AppBrand]: string };

const DEEPLINK_SCHEMAS: StringPerEnvironment = {
  [Environment.LOCAL]: 'boltdriverapp://',
  [Environment.PRELIVE]: 'boltdriverapp://',
  [Environment.LIVE]: 'boltdriverapp://',
  [Environment.PRELIVEHOPP]: 'boltdriverappprelive-hopp://',
  [Environment.LIVEHOPP]: 'boltdriverapp-hopp://',
};

const DRIVER_PORTAL_DOMAIN: StringPerEnvironment = {
  [Environment.LOCAL]: 'http://localhost:4200',
  [Environment.PRELIVE]: 'https://partners.prelive.bolt.eu',
  [Environment.LIVE]: 'https://partners.bolt.eu',
  [Environment.PRELIVEHOPP]: 'https://partners.prelive.gethopp.com',
  [Environment.LIVEHOPP]: 'https://partners.gethopp.com',
};

const SIGNUP_LANDING_DOMAIN: StringPerEnvironment = {
  [Environment.LOCAL]: 'http://localhost:4200',
  [Environment.PRELIVE]: 'https://prelive.bolt.eu',
  [Environment.LIVE]: 'https://bolt.eu',
  [Environment.PRELIVEHOPP]: 'https://prelive.gethopp.com',
  [Environment.LIVEHOPP]: 'https://gethopp.com',
};

const COOKIE_DOMAIN: StringPerEnvironment = {
  [Environment.LOCAL]: 'localhost',
  [Environment.PRELIVE]: '.prelive.bolt.eu',
  [Environment.LIVE]: '.bolt.eu',
  [Environment.PRELIVEHOPP]: '.prelive.gethopp.com',
  [Environment.LIVEHOPP]: '.gethopp.com',
};

const APP_STORE_LINK: StringPerBrand = {
  [AppBrand.BOLT]: 'https://boltdriver.onelink.me/phUm/lvew0z3s',
  [AppBrand.HOPP]: '', // TODO: replace when hopp link is ready
};

const HUAWEI_STORE_LINK: StringPerBrand = {
  [AppBrand.BOLT]: 'https://appgallery.huawei.com/app/C104321709',
  [AppBrand.HOPP]: '', // TODO: replace when hopp link is ready
};

const VEHICLE_MARKETPLACE_DOMAIN: StringPerEnvironment = {
  [Environment.LOCAL]: 'http://localhost:3000',
  [Environment.PRELIVE]: 'https://vehicle.prelive.bolt.eu',
  [Environment.LIVE]: 'https://vehicle.bolt.eu',
  [Environment.PRELIVEHOPP]: 'https://vehicle.prelive.gethopp.com',
  [Environment.LIVEHOPP]: 'https://vehicle.gethopp.com',
};

export const getCurrentEnvironment = (): Environment => {
  if (process.env.TARGET_ENV) {
    switch (process.env.TARGET_ENV) {
      case 'prelive':
        return Environment.PRELIVE;
      case 'prelivehopp':
        return Environment.PRELIVEHOPP;
      case 'live':
        return Environment.LIVE;
      case 'livehopp':
        return Environment.LIVEHOPP;
      case 'local':
      default:
        return Environment.LOCAL;
    }
  }

  const hostUrl = window.location.host;
  if (hostUrl.includes('.prelive.gethopp.com')) {
    return Environment.PRELIVEHOPP;
  }
  if (hostUrl.includes('.prelive.bolt')) {
    return Environment.PRELIVE;
  }
  if (hostUrl.includes('.gethopp.com')) {
    return Environment.LIVEHOPP;
  }
  if (hostUrl.includes('localhost') || hostUrl.includes('docker.internal')) {
    return Environment.LOCAL;
  }
  return Environment.LIVE;
};

export const getAppBrand = (): AppBrand => {
  const environment = getCurrentEnvironment();
  if (
    environment === Environment.PRELIVEHOPP ||
    environment === Environment.LIVEHOPP
  ) {
    return AppBrand.HOPP;
  }
  return AppBrand.BOLT;
};

export const getDeeplinkScheme = (): string => {
  return DEEPLINK_SCHEMAS[getCurrentEnvironment()];
};

export const getDriverPortalDomain = (): string => {
  return DRIVER_PORTAL_DOMAIN[getCurrentEnvironment()];
};

export const getSignupLandingDomain = (): string => {
  return SIGNUP_LANDING_DOMAIN[getCurrentEnvironment()];
};

export function getDomainForCookies(): string {
  return COOKIE_DOMAIN[getCurrentEnvironment()];
}

export function getLinkToAppStore(): string {
  return APP_STORE_LINK[getAppBrand()];
}

export function getLinkToHuaweiStore(): string {
  return HUAWEI_STORE_LINK[getAppBrand()];
}

export function getLinkToVehicleMarketplace(): string {
  return VEHICLE_MARKETPLACE_DOMAIN[getCurrentEnvironment()];
}

export function getServerHost(): string {
  switch (getCurrentEnvironment()) {
    case Environment.LOCAL:
      return 'http://node.local.internal';
    case Environment.PRELIVE:
    case Environment.PRELIVEHOPP:
      return 'https://prelive.boltsvc.net';
    case Environment.LIVE:
    case Environment.LIVEHOPP:
    default:
      return 'https://live.boltsvc.net';
  }
}

export function getGeoServerHost(): string {
  switch (getCurrentEnvironment()) {
    case Environment.LOCAL:
      return 'http://node.local.internal/geo';
    case Environment.PRELIVE:
    case Environment.PRELIVEHOPP:
      return 'https://geo.prelive.bolt.eu';
    case Environment.LIVE:
    case Environment.LIVEHOPP:
    default:
      return 'https://geo.bolt.eu';
  }
}

export function getIncogniaAppId(): string | null {
  switch (getCurrentEnvironment()) {
    case Environment.PRELIVE:
    case Environment.PRELIVEHOPP:
      return '2f9c003c-de23-41ee-8d4d-7704f89df209';
    case Environment.LIVE:
    case Environment.LIVEHOPP:
      return '6ec34128-0dbf-4207-9220-cc319b3a27f5';
    case Environment.LOCAL:
    default:
      return null;
  }
}
